import React from 'react'
import Link from 'next/link'
import {motion} from 'framer-motion'
import Image, {StaticImageData} from 'next/image'
import clsx from 'clsx'
import {INavigationLink} from 'v1/content/navigation'
import arrowUpRightIcon from 'v1/assets/icons/navigation/arrow-up-right.svg'
import classes from './styles.module.css'

interface SolutionsTabsProps {
  navItem: INavigationLink
  isWebNavOpen: boolean
  onClose: () => void
}

interface CardProps {
  image?: StaticImageData
  text?: string
  link?: string
  linkText?: string
  icon?: StaticImageData
}

const AnimatedContainer: React.FC<{
  isOpen: boolean
  children: React.ReactNode
  onClose: () => void
}> = ({isOpen, children, onClose}) => (
  <div className={classes.overlayBackground} onClick={onClose}>
    <motion.div
      initial={{y: 0, opacity: 0.4}}
      animate={isOpen ? {y: 0, opacity: 1} : {y: -10, opacity: 0}}
      exit={{y: -80, opacity: 0}}
      transition={{duration: 0.3}}
      className={classes.navigationListMainContainer}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </motion.div>
  </div>
)

const TabLinks: React.FC<{
  links: {
    url: string
    icon?: StaticImageData
    mobIcon?: StaticImageData
    title: string
    external?: boolean
  }[]
  isMob?: boolean
}> = ({links, isMob}) => (
  <div className={classes.listContainer}>
    {links.map((link, idx) => (
      <div key={idx} className={isMob ? classes.mobList : ''}>
        {isMob && <Image loading="lazy" src={arrowUpRightIcon} alt="arrow" />}
        <Link
          rel="preload"
          href={link.url}
          target={link?.external ? '_blank' : undefined}
          style={{width: '100%'}}
        >
          <div className={classes.list}>
            <Image loading="lazy" src={(isMob ? link.mobIcon : link.icon) || ''} alt="icon" />
            <p className={classes.tabText}>{link.title}</p>
          </div>
        </Link>
      </div>
    ))}
  </div>
)

const Card: React.FC<CardProps> = ({image, text, link, linkText, icon}) => (
  <div className={classes.blogCard}>
    <div className={classes.rightSideInnerCard}>
      <Image loading="lazy" src={image || ''} alt="image" />
      <h5 className={clsx('h6-v1', classes.cardText)}>{text}</h5>
    </div>
    <Link rel="preload" href={link || ''} className={clsx('h7-v1', classes.cardLinkText)}>
      {linkText}
      <Image loading="lazy" src={icon || ''} alt="icon" />
    </Link>
  </div>
)

export default function SolutionsTab({navItem, isWebNavOpen, onClose}: SolutionsTabsProps) {
  return (
    <AnimatedContainer isOpen={isWebNavOpen} onClose={onClose}>
      <div className={classes.navLinksAndTabsContainer}>
        <div className={classes.navLinksAndTabs}>
          <div className={classes.leftListContainer}>
            <p className={clsx(classes.heading, classes.plr12)}>{navItem?.heading}</p>
            <TabLinks links={navItem?.links || []} />
          </div>
          <div className={clsx(classes.leftListContainer, classes.rightCardContainer)}>
            {navItem.heading === 'Solutions' ? (
              <>
                <p className={classes.heading}>{navItem?.customersData?.title}</p>
                <div className={classes.customerCard}>
                  <Image loading="lazy" src={navItem?.customersData?.card?.logo || ''} alt="logo" />
                  <p className="paragraph2-v1">{navItem?.customersData?.card?.text}</p>
                  <div className={classes.customerCardFooter}>
                    <Image
                      loading="lazy"
                      src={navItem?.customersData?.card?.details.image || ''}
                      alt="image"
                    />
                    <p className="h8-v1">{navItem?.customersData?.card?.details.text}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className={classes.heading}>{navItem?.blogData?.title}</p>
                <div className={classes.blogCardContainer}>
                  {navItem?.blogData?.cards?.map((card, idx) => (
                    <Card
                      key={idx}
                      image={card?.image}
                      text={card?.text}
                      link={card?.link}
                      linkText={card?.linkText}
                      icon={card?.icon}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </AnimatedContainer>
  )
}

export function SolutionsMobileTab({navItem}: SolutionsTabsProps) {
  return (
    <div>
      <TabLinks isMob={true} links={navItem?.links || []} />
      {navItem.heading === 'Company' && (
        <div className={clsx(classes.leftListContainer, classes.rightCardContainer)}>
          <p className={classes.heading}>{navItem?.blogData?.title}</p>
          <div className={classes.blogCardContainer}>
            {navItem?.blogData?.cards?.map((card, idx) => (
              <Card
                key={idx}
                image={card?.image}
                text={card?.text}
                link={card?.link}
                linkText={card?.linkText}
                icon={card?.icon}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
