'use client'
import * as React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import clsx from 'clsx'
import Button, {ButtonType} from 'v1/common-components/button/Button'
import {HYBRID_APP} from 'v1/utils/getters'
import {navigationLinks} from 'v1/content/navigation'
import ProductTabs, {MobileProductNav} from './product-tabs/ProductTabs'
import SolutionsTab, {SolutionsMobileTab} from './solutions-company-tabs/SolutionAndCompanyTabs'
import Modal from 'v1/common-components/modal/Modal'
import BookDemoForm from 'v1/page-components/marketing/common/demo-form/BookDemoForm'

import SmallLogo from 'v1/assets/icons/small-logo.svg'
import ZenAdminLogo from 'v1/assets/icons/zenadmin-logo.svg'
import LoginIcon from 'v1/assets/icons/login-icon.svg'
import ArrowRightIcon from 'v1/assets/icons/arrow-right.svg'
import chevronUpIcon from 'v1/assets/icons/navigation/chevron-up.svg'
import ChevronDownWhite from 'assets/icons/common/chevron-down-white.svg'
import menuIcon from 'v1/assets/icons/navigation/menu.svg'
import crossIcon from 'v1/assets/icons/navigation/cross-icon.svg'
import classes from './styles.module.css'

interface NavigationProps {
  customLinkClassName?: string
  isDarkDownArrow?: boolean
  signInBtnVariant?: ButtonType
  signInBtnCustomCss?: React.CSSProperties
  customLineClassName?: string
  withoutList?: boolean
  withoutLogingBtn?: boolean
}

interface NavItem {
  heading: string
  url?: string
  external?: boolean
}
interface DemoData {
  isFreeTrialPage?: boolean
  isNameRequired?: boolean
  heading?: string
  buttonText?: string
  isTermAndCondition?: boolean
  thankYouTitle?: string
  thankYouParagraph?: string
}

interface RenderActionButtonProps {
  text: string
  iconRight?: React.ReactNode
  href?: string
  styles?: React.CSSProperties
  className?: string
  onClick?: () => void
}

const defaultDemoData = {
  isFreeTrialPage: true,
  isNameRequired: true,
  heading: 'Get ZenAdmin for Free',
  buttonText: 'Submit',
  isTermAndCondition: true,
  thankYouTitle: 'Your Account Has Been Created',
  thankYouParagraph:
    'Please check your email for login details to access your ZenAdmin account. Meanwhile, you can schedule a convenient time for a personalized demo.',
}

const Navigation: React.FC<NavigationProps> = ({
  withoutList = false,
  withoutLogingBtn = false,
  customLinkClassName = '',
  isDarkDownArrow = true,
  signInBtnVariant = 'secondary',
  signInBtnCustomCss,
  customLineClassName = '',
}) => {
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false)
  const [activeNav, setActiveNav] = React.useState<string | null>(null)
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false)
  const [demoData, setDemoData] = React.useState<DemoData>({...defaultDemoData})

  const toggleDropdown = (heading: string) => {
    setActiveNav(activeNav === heading ? null : heading)
  }

  const toggleDemoFormModal = (data: DemoData | null = null) => {
    setDemoData(
      data || {
        ...defaultDemoData,
      },
    )
    setModalOpen(prev => !prev)
  }

  const renderDropdownNavItem = (navItem: NavItem, Component: React.FC<any>) => (
    <div
      className={clsx(
        classes.link,
        classes.dropdownGroup,
        customLinkClassName,
        activeNav === navItem.heading ? classes.activeNavText : '',
      )}
      onClick={() => toggleDropdown(navItem.heading)}
    >
      {navItem.heading}
      <Image
        loading="lazy"
        src={isDarkDownArrow ? chevronUpIcon : ChevronDownWhite}
        alt="chevron-down"
        height={20}
        width={20}
        className={classes.downArrow}
      />
      {activeNav === navItem.heading && (
        <Component navItem={navItem} isWebNavOpen onClose={() => toggleDropdown(navItem.heading)} />
      )}
    </div>
  )

  const renderMobileDropdownNavItem = (navItem: NavItem, Component: React.FC<any>) => (
    <div
      className={clsx(
        classes.link,
        classes.mobileDropdownGroup,
        customLinkClassName,
        activeNav === navItem.heading ? classes.activeNavText : '',
      )}
      onClick={() => toggleDropdown(navItem.heading)}
    >
      <div className={classes.mobileDropdown}>
        <p
          className={clsx(
            classes.mobNavText,
            activeNav === navItem.heading ? classes.activeText : '',
          )}
        >
          {navItem.heading}
        </p>
        <Image
          loading="lazy"
          src={isDarkDownArrow ? chevronUpIcon : ChevronDownWhite}
          alt="chevron-down"
          height={20}
          width={20}
          className={classes.downArrow}
        />
      </div>
      {activeNav === navItem.heading && (
        <Component navItem={navItem} isWebNavOpen onClose={() => toggleDropdown(navItem.heading)} />
      )}
    </div>
  )

  const renderExternalNavItem = (navItem: NavItem, index: number) => (
    <a
      key={index}
      href={navItem.url}
      className={clsx(classes.link, customLinkClassName)}
      target="_blank"
      rel="noreferrer"
    >
      {navItem.heading}
    </a>
  )

  const renderInternalNavItem = (navItem: NavItem, index: number) => (
    <Link
      rel="preload"
      key={index}
      href={navItem.url || ''}
      className={clsx(classes.link, customLinkClassName)}
    >
      <div className={classes.mobileDropdown}>
        <p className={classes.mobNavText}>{navItem.heading}</p>{' '}
      </div>
    </Link>
  )

  const renderNavItem = (navItem: NavItem, index: number) => {
    const components: Record<string, React.FC<any>> = {
      Products: ProductTabs,
      Solutions: SolutionsTab,
      Company: SolutionsTab,
    }
    const Component = components[navItem.heading]

    return Component
      ? renderDropdownNavItem(navItem, Component)
      : navItem.external
      ? renderExternalNavItem(navItem, index)
      : renderInternalNavItem(navItem, index)
  }

  const renderMobileNavItem = (navItem: NavItem, index: number) => {
    const components: Record<string, React.FC<any>> = {
      Products: MobileProductNav,
      Solutions: SolutionsMobileTab,
      Company: SolutionsMobileTab,
    }
    const Component = components[navItem.heading]

    return Component
      ? renderMobileDropdownNavItem(navItem, Component)
      : navItem.external
      ? renderExternalNavItem(navItem, index)
      : renderInternalNavItem(navItem, index)
  }

  const renderActionButton = ({
    text,
    iconRight,
    href = '',
    styles = {},
    className = '',
    onClick,
  }: RenderActionButtonProps) => (
    <Button
      variant="primary"
      text={text}
      iconRight={iconRight}
      className={className}
      style={{gap: '9px', ...styles}}
      onClick={onClick}
    />
  )

  const renderNavLinks = () => (
    <>
      {!withoutLogingBtn && (
        <Link rel="preload" href={HYBRID_APP} target="_blank">
          <Button
            iconLeft={<Image src={LoginIcon} alt="login-icon" />}
            variant={signInBtnVariant}
            text="Login"
            style={signInBtnCustomCss}
          />
        </Link>
      )}

      {renderActionButton({
        text: 'Book a demo',
        className: classes.customButton,
        onClick: () => toggleDemoFormModal({}),
      })}
      {renderActionButton({
        text: 'Get Started',
        iconRight: (
          <span className={classes.btnIconBox}>
            <Image loading="lazy" src={ArrowRightIcon} alt="arrow-right" width={18} height={18} />
          </span>
        ),
        onClick: () => toggleDemoFormModal(),
      })}
    </>
  )

  const renderMobNavLinks = () => (
    <>
      {renderActionButton({
        text: 'Book a demo',
        iconRight: (
          <span className={classes.btnIconBox}>
            <Image loading="lazy" src={ArrowRightIcon} alt="arrow-right" width={18} height={18} />
          </span>
        ),
        onClick: () => toggleDemoFormModal({}),
      })}
    </>
  )

  const renderHamburgerNavLinks = () => (
    <>
      {renderActionButton({
        text: 'Get Started',
        styles: {minHeight: '32px'},
        className: classes.customButton,
        onClick: () => toggleDemoFormModal(),
      })}
      {renderActionButton({
        text: 'Book a demo',
        iconRight: (
          <span className={classes.btnIconBox}>
            <Image loading="lazy" src={ArrowRightIcon} alt="arrow-right" width={18} height={18} />
          </span>
        ),
        styles: {minHeight: '32px', height: '32px'},
        onClick: () => toggleDemoFormModal({}),
      })}
    </>
  )

  return (
    <>
      <nav className={classes.navbar}>
        <div className={classes.navbarInnerContainer}>
          <div className={classes.navleftBoxContainer}>
            {!withoutList && (
              <div
                className={clsx(classes.hamburger, {[classes.active]: isMobileNavOpen})}
                onClick={() => setMobileNavOpen(!isMobileNavOpen)}
              >
                <Image loading="lazy" src={menuIcon} width={24} alt="menu-icon" />
              </div>
            )}
            <div className={classes.logo}>
              <Link rel="preload" href="/" className={classes.companyLogo}>
                <Image loading="lazy" src={SmallLogo} alt="logo" height={28} />
                <Image loading="lazy" src={ZenAdminLogo} alt="logo" height={16} />
              </Link>
            </div>
          </div>

          {!withoutList && (
            <div className={classes.links}>
              {navigationLinks.map((navItem, index) => (
                <React.Fragment key={index}>{renderNavItem(navItem, index)}</React.Fragment>
              ))}
            </div>
          )}
          <div>
            <div className={classes.actionMobBtnContainer}>{renderMobNavLinks()}</div>
            <div className={classes.actionWevBtnContainer}>{renderNavLinks()}</div>
          </div>
        </div>
      </nav>

      <nav className={clsx(classes.mobileNavbar, {[classes.open]: isMobileNavOpen})}>
        <div className={classes.mobileNavbarInner}>
          <div className={classes.hamburgerHeader}>
            <Image
              loading="lazy"
              width={24}
              src={crossIcon}
              onClick={() => setMobileNavOpen(false)}
              alt="cross-icon"
            />
            <div className={classes.hamburgerBtnContainer}>{renderHamburgerNavLinks()}</div>
          </div>
          <div className={clsx(classes.mobileLinks)}>
            {navigationLinks.map((navItem, index) => (
              <React.Fragment key={index}>{renderMobileNavItem(navItem, index)}</React.Fragment>
            ))}
          </div>
        </div>
      </nav>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <BookDemoForm data={demoData} />
      </Modal>
    </>
  )
}

export default Navigation
