import clsx from 'clsx'
import Link from 'next/link'

import classes from './styles.module.css'
import {FooterProductList} from 'v1/types/home-page'

interface FooterProps {
  data: any
  customFooterListContainer?: string
  customListContainer?: string
}

export default function ListSection({
  data,
  customListContainer,
  customFooterListContainer,
}: FooterProps) {
  return (
    <div>
      <section className={classes.footerSection}>
        <h5 className={classes.heading}>{data?.heading}</h5>
        <div className={clsx(classes.footerListContainer, customFooterListContainer)}>
          {data?.list?.map((footerSubList: any, listIndex: number) => (
            <div key={listIndex} className={classes.footerRowContainer}>
              {footerSubList?.map((row: any, rowIndex: number) => (
                <div key={rowIndex} className={classes.footerRow}>
                  {row?.title ? <h6 className={classes.rowTitle}>{row?.title}</h6> : null}
                  <ul className={clsx(classes.listContainer, customListContainer)}>
                    {row?.subList?.map((item: FooterProductList, itemIndex: number) => (
                      <li className={classes.list} key={itemIndex}>
                        {item?.link ? (
                          <Link rel="preload" key={itemIndex} href={item.link}>
                            {item?.flag
                              ? getListWithFlag(item?.name, item?.flag, item?.flagText)
                              : item?.name}
                          </Link>
                        ) : (
                          <span>{item?.name}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

function getListWithFlag(listText: string, flag?: string, flagText?: string) {
  if (flag === 'hiring')
    return (
      <div>
        {listText} <span className={classes.hiringFlagText}>{flagText}</span>
      </div>
    )
  else if (flag === 'coming-later')
    return (
      <div className={classes.comingLetterFlag}>
        {listText} <p>{flagText}</p>
      </div>
    )
  else return
}
